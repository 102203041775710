import React from 'react';
import { Dispatch } from 'redux';
import { Diff } from 'utility-types';
import { connect } from 'react-redux';
import { setLanguage } from '@modules/translate/actions';
import { AppStorageType } from '@app-types/appStorageType';
import { TranslateChanger, WithTranslation } from '@modules/translate/types/withTranslation';
import { TranslateFunction } from '@modules/translate/components/TranslateFunction';
import { ITranslate, ITranslateContext } from '@modules/translate/types/translate';
import { TranslateContext } from '@contexts/translate';
import { withSession } from '@components/withSession';
import { IAuthContextWithUpdate } from '@modules/auth/types/SessionProps';
import { setEditTranslateKeyAction } from '@modules/translate/actions/actionAdmin';
import { loadTranslates } from '@commands/translate';

const withLocale = <P extends WithTranslation>(WrappedComponent: React.ComponentType<P>, translates?: ITranslate) => {
  const mapStateToProps = (state: AppStorageType) => {
    return ({
      currentLanguage: state.translate.user.currentLanguage,
      translates: state.translate.user.translates,
      langs: state.translate.user.langs,
      editTranslate: state.translate.admin.editTranslate,
    });
  };

  const mapDispatchToProps = (dispatch: Dispatch): TranslateChanger => ({
    setLanguage: (code: string) => dispatch(setLanguage(code)),
    setEditKey: (key: string) => dispatch(setEditTranslateKeyAction(key)),
    loadTranslateAction: (key: string) => dispatch(loadTranslates(key)),
  });

  type HocProps = IAuthContextWithUpdate & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

  class WithLocal extends React.Component<HocProps> {
    render() {
      return (
        <TranslateContext.Consumer>
          {(contexts: ITranslateContext) => {
            const { translatesServer, currentLanguageServer } = contexts;
            const {
              user,
              userId,
              update,
              setEditKey,
              translates,
              currentLanguage,
              editTranslate,
              loadTranslateAction,
              ...fields
            } = this.props;

            const __translates = { ...(translatesServer || {}), ...(translates || {}) };
            const lang = currentLanguageServer || currentLanguage;
            const edit = user?.isAdmin && editTranslate;
            const tr = (key: string,  ignoreSkeleton: boolean) => {
              if (!__translates[key]) {
                loadTranslateAction(key);
                if (typeof window !== 'object') {
                  return `____${key}____`;
                }

                if (ignoreSkeleton) {
                  return key
                }
                return <span className="loading-translate-key-item">{key}</span>;
              }

              return __translates[key];
            };
            const translate = TranslateFunction(tr, edit, setEditKey);
            const newProps = {
              ...fields,
              translates: __translates,
              currentLanguage: lang,
              translate,
            };
            return <WrappedComponent {...newProps as P} />;
          }}
        </TranslateContext.Consumer>
      );
    }
  }

  return connect<ReturnType<typeof mapStateToProps>,
    TranslateChanger,
    Diff<P, WithTranslation>,
    AppStorageType>(mapStateToProps, mapDispatchToProps)(withSession(WithLocal));
};

export default withLocale;
