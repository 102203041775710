import React, { FunctionComponent } from 'react';
import withLocale from '@modules/translate/withLocale';
import { WithTranslation } from '@modules/translate/types/withTranslation';
import LandingLayout from '@layouts/LandingLayout';
import Button from '@components/Button';

const Page404: FunctionComponent<WithTranslation> = (props) => {
  const {
    translate,
  } = props;

  return (
    <LandingLayout>
      <section role="main">
        <div className="section section_404">
          <div className="page404 aos-init aos-animate" data-aos="fade-up">
            <div className="page404__img">
              <img src="/landing/svg/404.svg" alt="404 icon" />
            </div>
            <div className="page404__title">
              {translate('pageNotFound.JKL')}
            </div>
            <div className="page404__text">
              <p>
                {translate('pageNotFound.AGQ')}
              </p>
            </div>
            <div className="page404__button">
              <Button link="/" className="btn_black btn_main btn_capitalize">
                {translate('pageNotFound.GQW')}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </LandingLayout>
  );
};

export default withLocale(Page404);
