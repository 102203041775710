import React, { FunctionComponent } from 'react';
import { Subtract } from 'utility-types';
import { AuthContext } from '@contexts/auth';
import { IAuthContext, IAuthContextWithUpdate } from '@modules/auth/types/SessionProps';
import { User } from '@modules/auth/types/Users';

export const withSession = <T extends IAuthContextWithUpdate>(Component: React.ComponentType<T>): FunctionComponent<Subtract<T, IAuthContextWithUpdate>> => {
  return (props: Subtract<T, IAuthContextWithUpdate>) =>
    <AuthContext.Consumer>
      {(contexts: IAuthContext) => {
        const user = contexts.user && new User(contexts.user) || null;
        return <Component {...props as T} user={user} userId={contexts.userId} />
      }}
    </AuthContext.Consumer>
}
